var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"viewStudentJoined"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbList,"divider":"/"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{attrs:{"to":item.href,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])}),(_vm.ExamData && _vm.ExamData.exam_details)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("Class"))+" "+_vm._s(_vm.ExamData.exam_details.class_name))])]),_c('div',{staticClass:"title"},[_c('h2',{staticClass:"main-color"},[_vm._v(" "+_vm._s(_vm.ExamData.exam_details.exam_name)+" - "+_vm._s(_vm.ExamData.exam_details.subject_name)+" ")])])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"info-list",attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('ul',{staticClass:"inline"},[_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Date"))+": ")]),_vm._v(_vm._s(_vm.ExamData.exam_details.date)+" ")]),_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Academic Period"))+":")]),_vm._v(" "+_vm._s(_vm.ExamData.exam_details.year_name)+" ")]),_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Qualifier"))+":")]),_vm._v(" "+_vm._s(_vm.ExamData.exam_details.qualifier_name)+" ")])])]),_c('v-col',{staticClass:"hr-div",attrs:{"cols":"12","md":"12"}},[_c('hr')])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"title mb-10"},[_c('h2',{staticClass:"main-color text-center dot redDot"},[_vm._v(" "+_vm._s(_vm.$t("Students Not Joined Exam Yet"))+" ")])]),_c('div',{staticClass:"students notjoined"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("Student Name")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("Student Code")))])])]),_c('tbody',_vm._l((_vm.ExamData.students
                  .not_joined),function(notJoinedStudent,index){return _c('tr',{key:index},[_c('td',[(notJoinedStudent.id)?_c('StudentHoverMenu',{attrs:{"studentName":notJoinedStudent.name,"studentId":notJoinedStudent.id,"userId":notJoinedStudent.user_id}}):_vm._e()],1),_c('td',[_vm._v(_vm._s(notJoinedStudent.code))])])}),0)]},proxy:true}])})],1)]),_c('v-col',{staticStyle:{"border-right":"1px solid #ddd"},attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"title mb-10"},[_c('h2',{staticClass:"main-color text-center dot greenDot"},[_vm._v(" "+_vm._s(_vm.$t("Students Joined Exam"))+" ")])]),_c('div',{staticClass:"students joined"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("Student Name")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("Student Code")))])])]),_c('tbody',_vm._l((_vm.ExamData.students.joined),function(joinedStudent,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(joinedStudent.name))]),_c('td',[_vm._v(_vm._s(joinedStudent.code))])])}),0)]},proxy:true}])})],1)]),_c('v-col',{staticStyle:{"border-right":"1px solid #ddd"},attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"title mb-10"},[_c('h2',{staticClass:"main-color text-center dot greenDot"},[_vm._v(" "+_vm._s(_vm.$t("Students Submitted Exam"))+" ")])]),_c('div',{staticClass:"students joined"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("Student Name")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("Student Code")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("Actions")))])])]),_c('tbody',_vm._l((_vm.ExamData.students
                  .submitted),function(submittedStudent,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(submittedStudent.name))]),_c('td',[_vm._v(_vm._s(submittedStudent.code))]),_c('td',[(_vm.ExamData.exam_details.show_delete_btn)?_c('v-icon',{on:{"click":function($event){return _vm.deleteSubmittedExam(submittedStudent.code)}}},[_vm._v("delete")]):_vm._e(),_c('router-link',{attrs:{"to":'/examCorrection/' +
                      _vm.academicYear +
                      '/' +
                      _vm.classId +
                      '/' +
                      _vm.examId +
                      '/' +
                      submittedStudent.id,"title":_vm.$t('Student Answers')}},[_c('v-icon',[_vm._v("assignment")])],1)],1)])}),0)]},proxy:true}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }